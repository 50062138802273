
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "notification-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    change: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
    },
  },

  setup: function (props) {
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    const notificationModalRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    let formRef = ref<null | HTMLElement>();
    let errorsRef = ref(null);

    let notificationData = ref({
      message: null,
    });

    watch(
      () => props.change,
      () => {
        notificationData.value = {
          message: null,
        };
        let button = document.getElementById("kt_modal_notification_cancel");
        button?.click();

        errorsRef.value = null;
      }
    );
    const validationSchema = Yup.object().shape({
      message: Yup.string().nullable().label("Notification Code"),
    });

    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };

    function getError(error) {
      errorsRef.value = error;
    }

    function success() {
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
      setTimeout(() => {
        Swal.fire({
          text: `Notification successfully sent!`,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(notificationModalRef.value);
        });
      }, 2000);
    }

    const submit = async () => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButtonRef.value || count > 0) {
        return;
      }
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      let data = {
        message: notificationData.value.message,
        user_id: props.id,
      };
      await apiService
        .post("/user/send/notification", data)
        .then(() => {
          hideLoading();
          success();
        })
        .catch(function (error) {
          hideLoading();
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          if (error.response && error.response.status == 422) {
            getError(error.response?.data.errors);
          }
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    function hideLoading() {
      if (submitButtonRef.value) {
        submitButtonRef.value.disabled = false;
        submitButtonRef.value?.removeAttribute("data-kt-indicator");
      }
    }
    return {
      validationSchema,
      submit,
      submitButtonRef,
      formRef,
      notificationData,
      notificationModalRef,
      errorsRef,
      hideError,
    };
  },
});
