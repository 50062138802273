
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import { Modal } from "bootstrap";
import DeleteUser from "@/components/swal/DeleteUser.vue";
import NotificationModal from "@/components/modals/forms/NotificationModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import DropdownExport from "@/components/dropdown/DropdownExport.vue";
import moment from "moment";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "user_table",
  components: { GlobalTable, DeleteUser, NotificationModal, DropdownExport },
  setup() {
    let item = ref();
    let datesQuery = ref();
    let searchDate = ref([]);
    let countryData = ref(0);
    let change = ref<boolean>(false);
    let updaterID = ref(null);
    let deleterData = ref({});
    let userID = ref(null);
    let checkDeleter = ref<boolean>(false);
    let checkNotification = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    const regions = ref([]);
    const allRegions = ref([]);
    let search = ref<string | null>(null);
    let getDataExist = ref<boolean>(true);
    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 1,
    });

    let page = ref<number>(1);
    let perPage = ref<number>(10);
    let data = ref([]);
    const router = useRouter();

    const tableHeader = [
      {
        name: "ID",
        key: "id",
        sortable: false,
      },
      {
        name: "date",
        key: "date",
        sortable: false,
      },
      {
        name: "company",
        key: "company",
        sortable: false,
      },
      {
        name: "name",
        key: "name",
        sortable: false,
      },
      {
        name: "email",
        key: "email",
        sortable: false,
      },
      {
        name: "region",
        key: "region",
        sortable: false,
      },
      {
        name: "Referral Code",
        key: "coupon",
        sortable: false,
      },
      {
        name: "Email Verification",
        key: "email_verified",
        sortable: false,
      },
      {
        name: "Result",
        key: "result",
        sortable: false,
      },
      {
        name: "Surveys Count",
        key: "surveys_count",
        sortable: false,
      },
      {
        name: "incomplete count",
        key: "incomplete_surveys",
        sortable: false,
      },

      {
        name: "Last score",
        key: "value_score",
        sortable: false,
      },

      {
        name: "Send Notification",
        key: "notification",
        sortable: false,
      },
      {
        name: "busy",
        key: "busy",
        sortable: false,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ];
    onMounted(() => {
      getUsers(perPage.value, page.value);
      getAllRegions();
    });

    function getUsers(perPage, page) {
      search.value = null;
      loading.value = true;
      let query = `?perPage=${perPage}&page=${page}&country=${countryData.value}`;
      if (searchDate.value && searchDate.value.length > 0) {
        query += `&startDate=${moment(searchDate.value[0]).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(searchDate.value[1]).format("YYYY-MM-DD")}`;
      }
      apiService
        .get("/user", query)
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //pagination.value.page = resp.data?.meta.current_page;
            //pagination.value.total = resp.data?.meta.total;
            //pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    function getAllRegions() {
      apiService
        .get(`/region`, `?column=name&orderType=asc`)
        .then(function (resp) {
          if (resp.data.data) {
            allRegions.value = resp.data?.data;
          }
        });
    }
    function searchUsers(perPage, page, query) {
      loading.value = true;
      let uri = `?query=${query}&perPage=${perPage}&page=${page}&country=${countryData.value}`;
      if (searchDate.value && searchDate.value.length > 0) {
        uri += `&startDate=${moment(searchDate.value[0]).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(searchDate.value[1]).format("YYYY-MM-DD")}`;
      }
      apiService
        .get("/user/search", uri)
        .then(function (resp) {
          data.value = resp.data.data;
          //pagination.value.page = resp.data.meta.current_page;
          //pagination.value.total = resp.data.meta.total;
          //pagination.value.rowsPerPage = resp.data.meta.per_page;
          loading.value = false;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    const getRegion = (resp) => {
      regions.value = resp;
    };
    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      if (search.value != null && search.value != "") {
        searchUsers(perPage.value, page.value, search.value);
      } else {
        getUsers(perPage.value, page.value);
      }
    };
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const changeCountry = () => {
      if (search.value != null && search.value != "") {
        searchUsers(perPage.value, page.value, search.value);
      } else {
        getUsers(perPage.value, page.value);
      }
    };
    const currentPageChange = (val) => {
      page.value = val;
      //pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchUsers(perPage.value, val, search.value);
      } else {
        getUsers(perPage.value, val);
      }
    };

    const setSearchValue = (event) => {
      search.value = event.target.value;
      if (!search.value?.trim()) {
        getUsers(perPage.value, page.value);
        return;
      }
      searchUsers(perPage.value, page.value, search.value);
    };
    const setSearchDate = () => {
      if (search.value != null && search.value != "") {
        searchUsers(perPage.value, page.value, search.value);
      } else {
        getUsers(perPage.value, page.value);
      }
    };

    const checkModal = (resp) => {
      if (resp.status == "update") {
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "insert") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        searchDate.value = [];
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };

    const deleteUser = (id, email) => {
      if (searchDate.value && searchDate.value.length > 0) {
        datesQuery.value = `&startDate=${moment(searchDate.value[0]).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(searchDate.value[1]).format("YYYY-MM-DD")}`;
      }
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        email: email,
      };
    };
    const sendNotification = (id) => {
      userID.value = id;
      checkNotification.value = !checkNotification.value;
      let modelDIv = document.getElementById("kt_modal_notification");
      modelDIv ? new Modal(modelDIv).show() : null;
    };

    const resendEmail = (id) => {
      apiService
        .get(`user/verification/${id}`)
        .then(({ data }) => {
          if (data?.message) {
            Swal.fire({
              text: data?.message,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };

    return {
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      item,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      deleteUser,
      checkNotification,
      sendNotification,
      userID,
      getDataExist,
      existData,
      resendEmail,
      searchDate,
      setSearchDate,
      countryData,
      changeCountry,
      datesQuery,
      getRegion,
      allRegions,
      regions,
    };
  },
});
