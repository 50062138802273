
import { defineComponent, onMounted } from "vue";
import UserTable from "@/components/widgets/tables/UserTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "users",
  components: {
    UserTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Users");
    });
  },
});
